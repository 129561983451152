import { gsap } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Observer } from "gsap/Observer";

import Swiper from "swiper";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(Observer);

gsap.registe;

if (document.getElementsByClassName("swiper-container").length > 0) {
  // var host = window.location.origin;
  // var script = document.createElement("script");
  // script.src = host + "/dist/about-detailed.20a78ec8.js";
  // document.head.appendChild(script);
}

document.addEventListener("DOMContentLoaded", function () {
  preCheck();
});

function preCheck() {
  //isModernBrowser = "visibilityState" in document;
  init();
}

function init() {
  initHeader.init();
  scrollNextButton.init();
  burgerMenuClick.init();
  imgSlideAnimation.init();
  textSlideInBottom.init();
  textSlideInLeft.init();
  textSlideInRight.init();
  slideInModal.init();
  tabbedContent.init();
  tabbedContentMaps.init();

  trackMenuClicks.init();

  //if detailed page
  if (document.getElementsByClassName("page-detailed").length > 0) {
    swiperCarousel.init();
    digitIncrement.init();
    trackModalClicks.init();
    trackDownloadPageClicks.init();
  }

  //if homepage
  if (document.getElementsByClassName("homepage").length > 0) {
    slidesShow.init();
    slidesMotion.init();
    trackDownloadClicks.init();
  }

  trackDownloadClicks.init();
}

/** Direction constants */
const NEXT = 1;
const PREV = -1;

class Slideshow {
  constructor(DOM_el) {
    this.DOM = {
      el: DOM_el, // Main slideshow container
      slides: null, // Individual slides
      slidesInner: null, // Inner content of slides (usually images)
    };
    this.current = 0;
    this.slidesTotal = 0;
    this.isAnimating = false;

    // Initialize DOM elements
    if (this.DOM.el) {
      this.DOM.slides = [...this.DOM.el.querySelectorAll(".slide")];
      this.DOM.slidesInner = this.DOM.slides.map((item) =>
        item.querySelector(".slide-content")
      );
      this.DOM.slides[this.current].classList.add("slide--current");
      this.slidesTotal = this.DOM.slides.length;
    }
  }

  next() {
    this.navigate(NEXT);
  }

  prev() {
    this.navigate(PREV);
  }

  navigate(direction) {
    if (this.isAnimating) return false;
    this.isAnimating = true;

    const previous = this.current;
    this.current =
      direction === NEXT
        ? this.current < this.slidesTotal - 1
          ? ++this.current
          : 0
        : this.current > 0
        ? --this.current
        : this.slidesTotal - 1;

    const currentSlide = this.DOM.slides[previous];
    const currentInner = this.DOM.slidesInner[previous];
    const upcomingSlide = this.DOM.slides[this.current];
    const upcomingInner = this.DOM.slidesInner[this.current];

    // Animation sequence using GSAP
    gsap
      .timeline({
        defaults: {
          duration: 1.5,
          ease: "power4.inOut",
        },
        onStart: () => {
          this.DOM.slides[this.current].classList.add("slide--current");
        },
        onComplete: () => {
          this.DOM.slides[previous].classList.remove("slide--current");
          this.isAnimating = false;
        },
      })
      .addLabel("start", 0)
      .to(
        currentSlide,
        {
          yPercent: -direction * 100,
        },
        "start"
      )
      .to(
        currentInner,
        {
          yPercent: direction * 30,
          startAt: {
            transformOrigin: direction === NEXT ? "0% 100%" : "100% 0%",
            rotation: 0,
          },
          rotation: -direction * 10,
          scaleY: 2.5,
        },
        "start"
      )
      .to(
        upcomingSlide,
        {
          startAt: {
            yPercent: direction * 100,
          },
          yPercent: 0,
        },
        "start"
      )
      .to(
        upcomingInner,
        {
          startAt: {
            transformOrigin: direction === NEXT ? "0% 0%" : "100% 100%",
            yPercent: -direction * 30,
            scaleY: 2.5,
            rotation: -direction * 10,
          },
          yPercent: 0,
          scaleY: 1,
          rotation: 0,
        },
        "start"
      );
  }
}

const slidesShow = {
  init: function (DOM_el) {
    return new Slideshow(DOM_el);
  },
};

//Tracking clicks
var trackMenuClicks = {
  init: function () {
    document.getElementById("menu").addEventListener("click", function (event) {
      if (event.target.classList.contains("menuLink")) {
        var linkUrl = event.target.getAttribute("href");
        var destinationPath = event.target.textContent;

        var eventData = {
          event: destinationPath,
          click_text: destinationPath,
          destination_page_path: destinationPath,
          link_url: linkUrl,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eventData);
      }
    });
  },
};

//Tracking clicks
var trackModalClicks = {
  init: function () {
    document
      .getElementById("waysinMenu")
      .addEventListener("click", function (event) {
        if (event.target.classList.contains("openz-modelz")) {
          var destinationPath = event.target.textContent;

          var eventData = {
            event: "in_page_button",
            click_text: destinationPath,
            destination_page_path: destinationPath,
            link_url: destinationPath,
            section: destinationPath,
          };

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(eventData);
        }
      });
  },
};

//Tracking clicks
var trackDownloadClicks = {
  init: function () {
    var downloadButtons = document.getElementsByClassName("download-cta");

    for (var i = 0; i < downloadButtons.length; i++) {
      downloadButtons[i].addEventListener("click", function (event) {
        var destinationPath = event.target.textContent;
        var linkUrl = event.target.getAttribute("href");

        var eventData = {
          event: "download",
          click_text: destinationPath,
          destination_page_path: destinationPath,
          link_url: linkUrl,
          section: destinationPath,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eventData);
      });
    }
  },
};

var trackDownloadPageClicks = {
  init: function () {
    var downloadButtons = document.getElementsByClassName(
      "download-cta--inner"
    );
    var pageSection = document.getElementById("page-section");

    for (var i = 0; i < downloadButtons.length; i++) {
      downloadButtons[i].addEventListener("click", function (event) {
        var destinationPath = event.target.textContent;
        var linkUrl = event.target.getAttribute("href");
        if (pageSection) {
          var sectionTitleText = pageSection.innerText;
        }
        var eventData = {
          event: "download",
          click_text: sectionTitleText + " - " + destinationPath,
          destination_page_path: destinationPath,
          link_url: linkUrl,
          section: sectionTitleText,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eventData);
      });
    }
  },
};

var slidesMotion = {
  init: function () {
    const slides = document.querySelector(".slider-wrapper");
    const slideshow = new Slideshow(slides);
    const nextLinks = document.querySelectorAll(".slides-nav__item--next");

    nextLinks.forEach((link) => {
      link.addEventListener("click", () => {
        slideshow.next();
      });
    });

    Observer.create({
      type: "wheel,touch,pointer",
      onDown: () => slideshow.prev(),
      onUp: () => slideshow.next(),
      wheelSpeed: -1,
      tolerance: 10,
    });
  },
};

//Slide in from Left
var textSlideInLeft = {
  init: function () {
    const slides = document.querySelectorAll(".ani-left");

    slides.forEach((slide, index) => {
      gsap.from(slide, {
        opacity: 0,
        x: -20,
        duration: 0.4,
        scrollTrigger: {
          trigger: slide,
          start: "bottom 100%",
          toggleActions: "play none none none",
        },
        delay: 0.078 * index,
      });
    });
  },
};

//Slide in from Right
var textSlideInRight = {
  init: function () {
    const slides = document.querySelectorAll(".ani-right");

    slides.forEach((slide, index) => {
      gsap.from(slide, {
        opacity: 0,
        x: 20,
        duration: 0.4,
        scrollTrigger: {
          trigger: slide,
          start: "bottom 100%",
          toggleActions: "play none none none",
        },
        delay: 0.078 * index,
      });
    });
  },
};

//tabbed content
var tabbedContent = {
  init: function () {
    const tabLinks = document.querySelectorAll(".tab-link");
    const tabMapLinks = document.querySelectorAll(".tab-link-map");
    const tabContents = document.querySelectorAll(".tab-content");
    const tabImage = document.getElementById("tabImage");

    tabMapLinks.forEach((link) => {
      link.addEventListener("mouseover", () => {
        const tabId = link.getAttribute("data-tab");

        tabContents.forEach((content) => {
          content.classList.remove("active");
        });

        tabLinks.forEach((tabLink) => {
          tabLink.classList.remove("active");
        });

        document.getElementById(tabId).classList.add("active");
        link.classList.add("active");
      });

      // link.addEventListener("mouseout", () => {
      //   // Reset the image source on mouseout
      //   tabImage.setAttribute("src", "tab1-image.svg");
      // });
    });

    tabLinks.forEach((link) => {
      link.addEventListener("mouseover", () => {
        const tabId = link.getAttribute("data-tab");

        tabContents.forEach((content) => {
          content.classList.remove("active");
        });

        tabLinks.forEach((tabLink) => {
          tabLink.classList.remove("active");
        });

        document.getElementById(tabId).classList.add("active");
        link.classList.add("active");

        tabImage.setAttribute("src", `${tabId}-image.svg`);
      });

      // link.addEventListener("mouseout", () => {
      //   // Reset the image source on mouseout
      //   tabImage.setAttribute("src", "tab1-image.svg");
      // });
    });

    // tabLinks.forEach((link) => {
    //   link.addEventListener("click", () => {
    //     const tabId = link.getAttribute("data-tab");

    //     tabContents.forEach((content) => {
    //       content.classList.remove("active");
    //     });

    //     tabLinks.forEach((tabLink) => {
    //       tabLink.classList.remove("active");
    //     });

    //     document.getElementById(tabId).classList.add("active");
    //     link.classList.add("active");
    //   });
    // });
  },
};

//tabbed content
var tabbedContentMaps = {
  init: function () {
    const tabLinks = document.querySelectorAll(".map-link");
    const tabContents = document.querySelectorAll(".map-content");

    const overlayItems = document.querySelectorAll(".overlay-item");

    overlayItems.forEach((toggleButton, index) => {
      toggleButton.addEventListener("click", () => {
        toggleContent(index);
      });

      function toggleContent(index) {
        const bam = index + 1;

        const poo = document.querySelector("#content" + bam + " .tabbed-map");
        const wee = document.querySelector("#content" + bam + " .country");
        const firstElement = poo.querySelector("div");
        const firstElementB = wee.querySelector("li");

        firstElement.classList.add("active");
        firstElementB.classList.add("active");
      }
    });

    tabLinks.forEach((link) => {
      link.addEventListener("click", () => {
        const tabId = link.getAttribute("data-tab");

        tabContents.forEach((content) => {
          content.classList.remove("active");
        });

        tabLinks.forEach((tabLink) => {
          tabLink.classList.remove("active");
        });

        document.getElementById(tabId).classList.add("active");
        link.classList.add("active");
      });
    });
  },
};

//image slide homepage
var imgSlideAnimation = {
  init: function () {
    const slides = document.querySelectorAll(".slide");

    slides.forEach((slide) => {
      const images = slide.querySelectorAll(".image");

      images.forEach((image, index) => {
        gsap.to(image, {
          opacity: 1,
          x: 0,
          y: 0,
          duration: 0.4,
          scale: 1,
          delay: index * 0.1,
          scrollTrigger: {
            trigger: slide,
            start: "top 10%",
            end: "bottom 0%",
            toggleActions: "play none none none",
          },
        });
      });
    });
  },
};

var digitIncrement = {
  init: function () {
    const elements = document.querySelectorAll(".number-increment");

    elements.forEach((element) => {
      const targetValue = parseFloat(element.getAttribute("data-value"));
      const startValue = parseFloat(element.innerHTML);

      const isFloating = targetValue % 1 !== 0;

      let number = startValue;

      const tl = gsap.timeline();

      tl.to(element, {
        duration: 1,
        innerHTML: number.toFixed(isFloating ? 1 : 0),
        ease: "power1.inOut",
      });

      ScrollTrigger.create({
        trigger: element,
        start: "top 80%",
        //end: "bottom center",
        onEnter: () => {
          tl.to(element, {
            innerHTML: targetValue.toFixed(isFloating ? 1 : 0),
            duration: 1,
            onUpdate: () => {
              const progress = tl.progress();
              const newValue = number + progress * (targetValue - number);
              element.innerHTML = newValue.toFixed(isFloating ? 1 : 0);
            },
          });
        },
      });
    });
  },
};

//swiper carousel
var swiperCarousel = {
  init: function () {
    if (document.getElementsByClassName("swiper-container").length > 0) {
      // eslint-disable-next-line no-unused-vars
      const mySwiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        loop: true,
        speed: 500,
        slidesPerView: 3,
        spaceBetween: 50,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });

      const mobileMediaQuery = window.matchMedia("(max-width: 767px)");
      // eslint-disable-next-line no-inner-declarations
      function handleMobileMediaQuery(mq) {
        if (mq.matches) {
          mySwiper.params.slidesPerView = 1;
          mySwiper.params.spaceBetween = 50;

          var nextButton = document.querySelector(".next");
          nextButton.addEventListener("click", function () {
            mySwiper.slideNext();
          });

          var prevButton = document.querySelector(".prev");
          prevButton.addEventListener("click", function () {
            mySwiper.slidePrev();
          });

          mySwiper.update();
        } else {
          mySwiper.params.slidesPerView = 3;
          mySwiper.update();
        }
      }
      mobileMediaQuery.addListener(handleMobileMediaQuery);
      handleMobileMediaQuery(mobileMediaQuery);
    }
  },
};

//scroll to next section
var scrollNextButton = {
  init: function () {
    // Get all elements with the class .scroll-next
    const scrollNextLinks = document.querySelectorAll(".scroll-next");

    // Add click event listeners to each of them
    scrollNextLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault(); // Prevent the default jump-to-anchor behavior

        // Remove the "active" class from all elements with the class ".slide"
        const allSlides = document.querySelectorAll(".slide");
        allSlides.forEach((slide) => {
          slide.classList.remove("active");
        });

        const targetId = link.getAttribute("href").substring(1); // Get the target section ID
        const targetSection = document.getElementById(targetId);

        if (targetSection) {
          // Add the "active" class to the parent slide
          const parentSlide = targetSection.closest(".slide");
          if (parentSlide) {
            parentSlide.classList.add("active");
          }

          // Smooth scroll to the target section
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      });
    });
  },
};

//header bar
var initHeader = {
  init: function () {
    let tl = gsap.timeline({ delay: 0.5 });
    let butt = gsap.timeline({ delay: 0.8 });
    let menu = gsap.timeline({ delay: 1 });

    tl.from(".logo", {
      y: -40,
      opacity: 0,
      duration: 0.5,
      ease: "elastic.out(1,0.3)",
    }).to(".logo", {
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: "elastic.out(1,0.3)",
    });

    butt
      .from(".download-cta", {
        y: -40,
        opacity: 0,
        duration: 0.1,
        ease: "elastic.out(1,0.3)",
      })
      .to(".download-cta", {
        y: 0,
        opacity: 1,
        duration: 0.1,
        ease: "elastic.out(1,0.3)",
      });
    menu
      .from(".menu-cta", {
        y: -40,
        opacity: 0,
        duration: 0.1,
        ease: "elastic.out(1,0.3)",
      })
      .to(".menu-cta", {
        y: 0,
        opacity: 1,
        duration: 0.1,
        ease: "elastic.out(1,0.3)",
      });
  },
};

//Burger Menu
var burgerMenuClick = {
  init: function () {
    var btn = document.getElementById("btn");
    var menu = document.getElementById("menu");
    var headerMenu = document.getElementById("main-header");

    //Toggle
    btn.addEventListener(
      "click",
      function (e) {
        e.preventDefault();
        menu.classList.toggle("open");
        headerMenu.classList.toggle("active");
      },
      false
    );

    //Preview
    window.onload = function () {
      setTimeout(function () {
        //menu.classList.add("open");//remove

        setTimeout(function () {
          menu.classList.remove("open");
          headerMenu.classList.remove("active");
        }, 1200);
      }, 600);
    };
  },
};

//Slide In Modal
var slideInModal = {
  init: function () {
    const overlayItems = document.querySelectorAll(".overlay-item");
    const overlayModal = document.querySelectorAll(".overlay-modal");
    const closeButton = document.querySelectorAll(".close-panel");
    const closeAllButtons = document.querySelectorAll(".close-panel");
    const headerMenu = document.getElementById("main-header");
    const tabLinks = document.querySelectorAll(".map-link");
    const tabContents = document.querySelectorAll(".map-content");

    overlayItems.forEach((toggleButton, index) => {
      toggleButton.addEventListener("click", () => {
        toggleContent(index);
        headerMenu.classList.toggle("active-modal");
      });

      closeButton[index].addEventListener("click", () => {
        hideContent(index);
        headerMenu.classList.remove("active-modal");

        tabContents.forEach((content) => {
          content.classList.remove("active");
        });

        tabLinks.forEach((tabLink) => {
          tabLink.classList.remove("active");
        });
      });

      for (var i = 0; i < closeAllButtons.length; i++) {
        (function (i) {
          closeAllButtons[i].onclick = function () {
            hideContent(index);
            headerMenu.classList.remove("active-modal");
          };
        })(i);
      }
    });

    function toggleContent(index) {
      overlayModal[index].classList.add("active");
    }

    function hideContent(index) {
      overlayModal[index].classList.remove("active");
    }
  },
};

//GSAP
var textSlideInBottom = {
  init: function () {
    //animate text from bottom up
    const textElements = document.querySelectorAll(".animate-text--bottom");
    textElements.forEach((textElement) => {
      const animation = gsap.fromTo(
        textElement,
        { y: "100%", opacity: 0 },
        { y: "0%", opacity: 1, duration: 0.6, ease: "power2.out" }
      );

      ScrollTrigger.create({
        trigger: textElement,
        start: "top 100%",
        animation: animation,
        toggleActions: "play none none none",
      });
    });
  },
};
